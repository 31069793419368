<template>
  <div class="Page" style="min-width: 1280px">
    <div class="flex-row justify-between items-center header space-x-8 fixed_top" >
      <div class="flex-row items-center">
        <img class="image" :src="require('@/assets/logo.png')" alt="logo"/>
        <img class="image_2" :src="require('@/assets/MAKAFIM.png')" alt="logo"/>
      </div>
      <div class="flex-row items-center justify-between" style="width: 50%;">
        <div class="flex-row items-center justify-evenly ul_router">
          <div @click="$router.push('/home/index')" :class="{'active':$route.path==='/home/index'}">首页</div>
          <div @click="$router.push('/home/instruction_manual')" :class="{'active':$route.path==='/home/instruction_manual' || $route.path==='/manual/detail' }">使用手册</div>
<!--          <div @click="$router.push('/home/maka_club')" :class="{'active':$route.path==='/home/maka_club'}">麦嘉俱乐部</div>-->
          <div @click="$router.push('/home/about_us')" :class="{'active':$route.path==='/home/about_us'}">关于我们</div>
        </div>
        <el-button v-if="!returnNickName" class="main_btn1 login_btn main_color" @click="ToLogin">注册/登录</el-button>
        <div v-else style="margin-left: 3.125rem;font-size: 1.125rem;position: relative;cursor: pointer" @mousemove="isShow=true" @mouseleave="isShow=false" >
          <div style="color: #FFBA56;line-height:3.63rem" >
            <i class="el-icon-s-custom" style="border: 1px solid #FFBA56;border-radius: 50%"></i> {{returnNickName}}
          </div>
          <ul class="is_login_ul" v-show="isShow">
            <li @click="ToLoginCenter">个人中心</li>
            <li @click="LoginOut">退出登录</li>
          </ul>
        </div>
      </div>
    </div>
      <div style="padding-top: 5rem">
        <keep-alive>
              <router-view/>
        </keep-alive>
      </div>
    <el-dialog
        :visible.sync="LoginVisible"
        width="75rem"
        :close-on-click-modal="false"
        class="details-dialog-css"
        @close="close"
    >
      <template slot="title">
        <div class="flex-row items-center">
          <img :src="require('@/assets/logo.png')" alt="" style="width: 2rem">
          <img :src="require('@/assets/MAKAFIM.png')" alt="" style="width: 6rem;margin-left: 0.6rem">
        </div>
      </template>
      <div class="flex-row items-center" style="height: 28.4rem;margin-top: -1.25rem">
          <div>
            <img :src="require('@/assets/login-bg.png')" alt="登录背景图" style="width: 36.375rem">
          </div>
          <div class="flex-col items-center justify-center" style="height: 100%;border-left: 1px solid #EFEFEF;padding: 0 4rem;flex: 1">
            <span  style="margin-bottom:2rem;color: #333333;font-size: 2rem;line-height: 1.97rem;">创建/登入您的账号</span>
            <el-form ref="Login" :rules="LoginRules" :model="LoginFormData" label-position="top" label-width="80px" style="width: 100%">
              <el-form-item prop="mobile">
                <template slot="label">
                  <span class="label_login">手机号</span>
                </template>
                <div class="get_login">
                    <el-input  placeholder="请输入您的手机号" v-model="LoginFormData.mobile" clearable></el-input>
                </div>
              </el-form-item>
              <el-form-item prop="vcode">
                <template slot="label">
                  <span class="label_login">验证码</span>
                </template>
                <div class="flex-row get_login">
                  <el-input  placeholder="请输入验证码" v-model="LoginFormData.vcode" clearable></el-input>
                  <el-button class="main_btn2 button_login_code main_color" @click="GetCode" :disabled="codeStatus" >{{codeText}}</el-button>
                </div>
              </el-form-item>
              <el-form-item>
                  <el-button class="main_btn2 submit_login main_color" style="margin-top: 1.625rem" @click="LoginSubmit">一键登录</el-button>
              </el-form-item>
            </el-form>
          </div>
      </div>
    </el-dialog>
<!--    <div class="flex-col items-center section fixed" style="cursor: pointer">-->
<!--      <div class="flex-col items-center">-->
<!--        <img-->
<!--            class="image_5"-->
<!--            :src="require('@/assets/Frame 14.png')"-->
<!--        />-->
<!--        <span class="font_3 text_4">在线咨询</span>-->
<!--      </div>-->
<!--       <div class="flex-col items-center" @click="ToTop">-->
<!--         <img-->
<!--             class="image_6"-->
<!--             :src="require('@/assets/Vector 2.png')"-->
<!--         />-->
<!--         <span class="font_3 text_5" @click="">返回顶部</span>-->
<!--       </div>-->
<!--    </div>-->
  </div>
</template>
<script>

import {log} from "mathjs";
import Vue from "vue";

export default {
  name: "StaticPage.vue",
  data(){
    var  validatemobile = (rule, value, callback) => {
      if(!(/^1[3456789]\d{9}$/.test(value))){
        callback(new Error('请输入正确的手机号码格式'));
      }
      callback()
    }
    return {
      //打开的新窗口
      windowView:'',
      //登录状态移入
      isShow:false,
      //移入框定时器
      MousePlace:'',
      //定时器
      timeStop:'',
      //验证码文本
      codeText:'获取验证码',
      //验证码状态
      codeStatus:false,
      //登录
      LoginVisible:false,
      LoginFormData:{
        mobile:'',
        vcode:'',
      },
      //推荐人信息
      PData:{
        pid:'',
        makafin_source:'1',
      },
      LoginRules:{
        mobile:[
          { required: true, message: '请输入您的手机号', trigger: 'blur'},
          {validator:validatemobile,trigger: 'blur'}
        ],
        vcode:[
          { required: true, message: '请输入验证码', trigger: 'blur'}
        ]
      }
    }
  },
  computed:{
    returnNickName(){
      return this.$store.getters.GetNickName
    },

  },
  mounted() {
    this.$utils.CacheSessionSet('hasLoginDialog',false)
    this.$store.commit('setNickName',this.$utils.CacheLocalGet('nickname') )
    if(this.$route.query.makafin_source){
      this.$utils.CacheSessionSet('query',this.$route.query)
    }
  },
  methods:{
    //去个人中心
    ToLoginCenter(){
      this.$api.post('v1/api/makafin/heartbeat',{},res=>{
        let routeUrl= this.$router.resolve({path:'/dashboard'})
        let windowView=window.open(routeUrl.href, '_blank');
        this.$store.commit('setWindowViewClose', windowView)
      },err=>{
        this.$message({
          message:'账号已退出,请重新登录',
          type:'error',
          offset:80,
          customClass:'messageIndex'
        })
        this.ClearDate()
      })
    },
    //关闭登录模态框
    close(){
      this.$utils.CacheSessionRemove('hasLoginDialog')
      this.LoginFormData={
        mobile:'',
        vcode:'',
      }
      this.codeText = '获取验证码';
      this.codeStatus = false;
      clearInterval(this.timeStop); //清除定时器
    },
    //去登录
    ToLogin(){
      this.$api.post('v1/api/makafin/heartbeat',{},res=>{
          // this.$message({
          //   message:'您的账号已登录',
          //   type:'warning',
          //   offset:80,
          //   customClass: 'messageIndex'
          // })
        let routeUrl= this.$router.resolve({path:'/dashboard'})
        let windowView=window.open(routeUrl.href, '_blank');
        this.$store.commit('setWindowViewClose', windowView)
      },err=>{
        if(!this.$utils.CacheSessionGet('hasLoginDialog')){
          this.$utils.CacheSessionSet('hasLoginDialog',true)
          this.LoginVisible=true
        }
      })
    },
    //获取验证码
    GetCode(){
      let query=this.$utils.CacheSessionGet('query') || this.PData
      if(!(/^1[3456789]\d{9}$/.test(this.LoginFormData.mobile))){
        this.$message.error('您的手机号格式不正确')
        return
      }
      this.codeStatus = true;
      this.$api.post(`v1/api/makafin/base_account/send/sms`, {...query,...this.LoginFormData},
          res=>{
            let num = 60;
            this.codeText = `已发送（${num} s）`;
            this.timeStop = setInterval(() => {
              num--;
              this.codeText = `已发送（${num} s）`;
              if (num === 0) {
                this.codeText = '获取验证码';
                this.codeStatus = false;
                clearInterval(this.timeStop); //清除定时器
              }
            }, 1000);
          }
      )
    },
    //登录
    LoginSubmit(){
      this.$refs['Login'].validate((valid) => {
        if (valid) {
          let query=this.$utils.CacheSessionGet('query') || this.PData
          this.$api.post('v1/api/makafin/base_account/login/vcode', {...query,...this.LoginFormData},
              res=>{
                this.$utils.cookieSet('__ERP_IDENTITY_NUMBER__', res.number);
                this.$utils.cookieSet('__ERP_IDENTITY_TOKEN__', res.token);
                this.$utils.cookieSet('__ERP_IDENTITY_TOKEN__KEY__', res.token_key);
                this.$utils.cookieSet('__ERP_USERNAME__', res.name);
                this.$utils.cookieSet('__ERP_SELLER_USERNAME__', res.seller_name);
                this.$utils.cookieSet('__ERP_SELLER_ID__', res.seller_id);
                this.$utils.CacheLocalSet('nickname', res.name)
                this.$store.commit('setNickName', res.name)
                let routeUrl= this.$router.resolve({path:'/dashboard'})
                this.LoginVisible=false
                let windowView=window.open(routeUrl.href, '_blank');
                this.$store.commit('setWindowViewClose', windowView)
              })
        }
      });
    },
    //退出登录
    LoginOut(){
      this.$confirm('确定退出系统吗?', {
        confirmButtonText: '确定',
        cancelButtonText:'取消',
        closeOnClickModal: false,
        customClass:'loginout_confirm',
      }).then(res=>{
        this.$api.post('v1/api/makafin/base_account/logout',{},res=>{
          this.ClearDate()
        },err=>{
          this.$message({
            message:'账号已退出,请重新登录',
            type:'error',
            offset:80,
            customClass:'messageIndex'
          })
          this.ClearDate()
        })
      }).catch(err=>{
        this.$message({
          message:'已取消退出',
          type:'info',
          offset:100,
          customClass:'messageIndex'
        })
      })
    },
    //清除数据
    ClearDate(){
      let sessionMoney=this.$utils.CacheSessionGet('moneyloans')
      let CloseAll= this.$store.getters.GetWindowViewClose
      if(CloseAll.length){
        CloseAll.forEach(item=>{
          item.close()
        })
        this.$store.commit('setWindowViewClose', '')
      }
      this.$utils.CacheSessionClear()
      this.$utils.CacheLocalClear()
      this.$utils.cookieRemove('__ERP_IDENTITY_NUMBER__');
      this.$utils.cookieRemove('__ERP_IDENTITY_TOKEN__');
      this.$utils.cookieRemove('__ERP_IDENTITY_TOKEN__KEY__');
      this.$utils.cookieRemove('__ERP_USERNAME__');
      this.$utils.cookieRemove('__ERP_SELLER_USERNAME__');
      this.$utils.cookieRemove('__ERP_SELLER_ID__');
      this.$store.commit('setNickName', '')
      this.$utils.CacheSessionSet('moneyloans',sessionMoney)
    },
    //回到顶部
    // ToTop(){
    //   var scrollToTop=setInterval(function() {
    //     var pos = window.pageYOffset;
    //     if ( pos >0 ) {
    //       window.scrollTo(0, pos - 30 );
    //     } else {
    //       window.clearInterval( scrollToTop );
    //     }
    //   }, 16);
    // }
  }
}
</script>
<style scoped>
.is_login_ul{
  position: absolute;
  top: 3.25rem;
  right: -50%;
  width: 9.5rem;
  height: 7rem;
  border-radius: 0.75rem;
  background: white;
}
.is_login_ul li{
  padding: 1rem 2.5rem;
}
.is_login_ul li:hover{
  color:#FFBA56;
}
.is_login_ul li:nth-child(1){
  border-bottom: 1px solid #DEDEDE;
}
.fixed_top{
  box-sizing: border-box;
  position:fixed;
  width: 100%;
  background:#fff;
  border-bottom: 1px solid #f2f2fa;
  z-index: 999999;
}
.section {
  padding: 2.13rem 0 2rem;
  background-color: #ffffffb3;
  border-radius: 4.63rem;
  box-shadow: -0.13rem 0.25rem 0.25rem #ececec80;
  width: 6.25rem;
}
.fixed{
  position: fixed;
  bottom: 11rem;
  right: 5.125rem;
  z-index: 100;
}
.image_5 {
  width: 3rem;
  height: 3rem;
}
.image_6 {
  margin-top: 2.07rem;
  width: 2.25rem;
  height: 1.31rem;
}
.font_3 {
  font-size: 0.75rem;
  line-height: 0.72rem;
}
.text_4 {
  margin-top: 0.75rem;
  color: #ff893f;
}

.text_5 {
  margin-top: 0.88rem;
  color: #66666666;
}
.button_login_code.el-button.is-disabled{
  background: linear-gradient(90deg, #ffbe58 0%, #ff863d 100%);
}
.ul_router .active{
  font-weight: bold;
}
.ul_router{
  flex: 1;
  font-size: 1.125rem;
}
.ul_router div{
  cursor: pointer;
}
.button_login_code{
  margin-left: 2rem;
  color: #000!important;
  font-size: 1.25rem;
  width: 11rem;
}
.label_login{
  font-size: 1.5rem;
  line-height: 1.44rem;
  color: #333333;
  padding-bottom:0;
}
.submit_login{
  width: 100%;
  font-size: 1.5rem;
  color: #333333;
}
</style>
<style>
.loginout_confirm .el-message-box__content, .loginout_confirm .el-message-box__btns{
  font-size: 1.625rem;
  text-align: center;
}
.loginout_confirm .el-button:nth-child(1){
  background: #FFF;
  width: 7.125rem;
  color: #000;
  font-size: 1.125rem;
  border:1px solid #FFBA56!important;
}
.loginout_confirm .el-button:nth-child(1):hover, .loginout_confirm .el-button:nth-child(1):focus{
  color:#FFBA56;
}
.loginout_confirm .el-button:nth-child(1):active{
  color:#FFBA56;
}
.loginout_confirm .el-button:nth-child(2){
  margin-left: 5.125rem;
  margin-top: 2.625rem;
  width: 7.125rem;
  color: #000;
  font-size: 1.125rem;
  background: #FFBA56;
}
.loginout_confirm .el-button:nth-child(2):hover,.loginout_confirm .el-button:nth-child(2):focus{
  background: #F8B14A;
  color:#000;
}
.loginout_confirm .el-button:nth-child(2):active{
  background: #F8B14A;
  color:#000;
}
.get_login{
  height: 3.5rem;
}
.get_login .el-input__inner{
  font-size: 1.25rem;
  height: 3.5rem;
}
.details-dialog-css{
  z-index: 999999;
}
.Page{
  height: 100%;
}
.login_btn.main_btn1.login_btn.main_color{
  font-size: 1.125rem;
  margin-left: 3.125rem;
  color: #FFFFFF;
}
.main_color.el-button{
  background: linear-gradient(90deg, #ffbe58 0%, #ff863d 100%);
}
.flex-col {
  display: flex;
  flex-direction: column;
}
.flex-row {
  display: flex;
  flex-direction: row;
}
.justify-between{
  justify-content: space-between;
}
.justify-evenly{
  justify-content: space-evenly;
}
.justify-center{
  justify-content: center;
}
.items-center {
  align-items: center;
}
.items-start{
  align-items: flex-start;
}
.header {
  padding: 0.685rem 4.63rem;
  background-color: #ffffff;
  filter: drop-shadow(0px 0px 2px #00000040);
}
.space-x-8 > *:not(:first-child) {
  margin-left: 0.5rem;
}
.image {
  width: 3rem;
  height: 3.63rem;
}
.image_2 {
  margin-left: 0.5rem;
  width: 10.5rem;
  height: 2rem;
}
</style>